.topic {
    background-color: #AFD2F3;
}

@media only screen and (min-width: 1280px) {
    .input-row-content{
        justify-content: left;
        margin-left: 8.5rem !important;
    }
}

@media only screen and (min-width: 1920px) {
    .input-row-content{
        justify-content: left;
        margin-left: 20rem !important;
    }
}

@media only screen and (max-width: 600px) {
    .input-row-content{
        justify-content: center !important;
    }
}