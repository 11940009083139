.result-title-bg {
    background-color: #AFD2F3;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.result-back-button {
    background-color: #FFFFFF !important;
    border: none !important;
    padding: 0 !important;
}

@media only screen and (min-width: 768px) {
    .result-title-img {
        margin-left: 4rem;
    }
    .result-back-button-margin {
        margin-left: 6.5rem;
        margin-top: 4rem;
    }

    .result-choice-title {
        margin-left: 9rem;
    }

    .result-choice-group {
        margin-left: 8rem;
    }

    .result-form {
        margin-left: 9rem;
    }

    .result-button-group{
        margin-left: 8rem !important;
    }

    .result-answer {
        margin-left: 1rem;
    }

    .result-br {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .result-back-button-margin {
        margin-left: 1.5rem;
        margin-top: 1.5rem;
    }

    .result-choice-title {
        margin-left: 1.5rem;
    }

    .result-choice-group {
        margin-left: .5rem;
        flex-wrap: wrap;
        /* flex-direction: column !important; */
    }

    /* .result-choice-group .list-group-item {
        width: 50% !important;
    } */

    .result-form {
        margin-left: 1.5rem;
    }
}