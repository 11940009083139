.homepage-title-row {
    background-image: url("../../../public/layer.png");
    /* background-image: url("/layer.png"); */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    justify-content:space-around;
}

@media only screen and (min-width: 768px) {
    .homepage-title-text-stack {
        width: 600px;
    }

    .homepage-person-img {
        margin-left: 5rem;
    }
}

@media only screen and (max-width: 600px) {
    .homepage-title-text-col {
        justify-items: center;
        text-align: center;
    }

    .homepage-title-text-stack {
        width: 300px;
        /* text-align: center; */
    }

    .homepage-title-row-gap{
        gap: 3rem !important;
    }
}