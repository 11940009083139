.badge {
    width: 115px !important;
    height: 48px !important;
    border: 1px solid #000 !important;
    background-color: #D9D9D9 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    /* text */
    color: #000 !important;
    /* font-family: Inter; */
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
}