
@media only screen and (min-width: 768px) {
    .header-navbar {
        margin-left: 5rem !important;
    }
}

@media only screen and (max-width: 600px) {
    .header-container {
        justify-content: center !important;
    }

    .header-navbar {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}
